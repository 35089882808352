import { hash } from 'ohash';

export type Client<ResponseType> = ReturnType<
  typeof $fetch.create<ResponseType>
>;
type Proc<RequestArgsType extends unknown[], ResponseType, ResultType> = (
  cli: Client<ResponseType>,
  ...p: RequestArgsType
) => Promise<ResultType>;

export const UNSPECIFIED: unique symbol = Symbol('');
type UNSPECIFIED = typeof UNSPECIFIED;

type Options = {
  tokenHeaderName?: string | UNSPECIFIED;
  contentType?: string | UNSPECIFIED;
};

type ServiceName = keyof ReturnType<typeof useServiceEndpoints>;
const useRestRequest = <
  RequestArgsType extends unknown[],
  ResponseType,
  ResultType,
>(
  serviceName: ServiceName,
  procedure: Proc<RequestArgsType, ResponseType, ResultType>,
  options?: Options
) => {
  // normalize options
  const tokenHeaderName = options?.tokenHeaderName ?? 'X-API-TOKEN';
  const contentType =
    options?.contentType === UNSPECIFIED
      ? undefined
      : (options?.contentType ?? 'application/json; charset=utf-8');

  return () => {
    const apiToken =
      tokenHeaderName !== UNSPECIFIED || !import.meta.server
        ? useHotelToken().token
        : ref(null);

    const endpoints = useServiceEndpoints();

    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    const headers: {} | Record<string, string> =
      contentType == undefined ? {} : { 'content-type': contentType };
    const cli = computed(() => {
      return $fetch.create<ResponseType>({
        baseURL: endpoints[serviceName],
        headers:
          tokenHeaderName === UNSPECIFIED
            ? headers
            : (apiToken.value?.modifyHeader(headers) ?? headers),
      });
    });

    return {
      fetch: (...params: RequestArgsType) => procedure(cli.value, ...params),
    };
  };
};

const useAsyncRestRequest = <
  RequestArgsType extends unknown[],
  ResponseType,
  ResultType,
>(
  serviceName: ServiceName,
  procedure: Proc<RequestArgsType, ResponseType, ResultType>,
  options?: Options
) => {
  return (...params: RequestArgsType) =>
    useAsyncData(
      hash({
        serviceName,
        procedure,
        options,
      }),
      () =>
        useRestRequest<RequestArgsType, ResponseType, ResultType>(
          serviceName,
          procedure,
          options
        )().fetch(...params)
    );
};

export const useRestClient = <
  K extends string,
  RequestArgsType extends unknown[],
  ResponseType,
  ResultType,
>(
  name: K,
  serviceName: ServiceName,
  procedure: Proc<RequestArgsType, ResponseType, ResultType>,
  options?: Options
): {
  [KEY in `use${K}` | `useAsync${K}`]: KEY extends `use${K}`
    ? ReturnType<
        typeof useRestRequest<RequestArgsType, ResponseType, ResultType>
      >
    : ReturnType<
        typeof useAsyncRestRequest<RequestArgsType, ResponseType, ResultType>
      >;
} =>
  ({
    [`use${name}`]: useRestRequest(serviceName, procedure, options),
    [`useAsync${name}`]: useAsyncRestRequest(serviceName, procedure, options),
  }) as {
    [KEY in `use${K}` | `useAsync${K}`]: KEY extends `use${K}`
      ? ReturnType<
          typeof useRestRequest<RequestArgsType, ResponseType, ResultType>
        >
      : ReturnType<
          typeof useAsyncRestRequest<RequestArgsType, ResponseType, ResultType>
        >;
  };
